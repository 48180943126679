import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";

const content = (t: any) => [
  {
    title: t("page.newAbout.intro.1.title"),
    content: t("page.newAbout.1.content"),
  },
  {
    title: t("page.newAbout.intro.2.title"),
    content: t("page.newAbout.intro.2.content"),
  },
  {
    title: t("page.newAbout.intro.3.title"),
    content: t("page.newAbout.intro.3.content"),
  },
];
export default function Intros() {
  const { t } = useTranslation();
  return (
    <div className="container">
      <ScrollContainer className="list-container">
        {content(t).map((item, index) => (
          <div key={index} className="item">
            <div className="title">{item.title}</div>
            <div className="content">{item.content}</div>
          </div>
        ))}
      </ScrollContainer>
    </div>
  );
}
