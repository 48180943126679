import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Import Swiper React components
import Intros from "@app-components/aboutUs/Intros";
import MemberInfos from "@app-components/aboutUs/MemberInfos";
import MidBanner from "@app-components/aboutUs/MidBanner";
import SliderMember from "@app-components/aboutUs/SliderMember";
import StyledButton from "@app-components/button/StyledButton";
import { PATHS } from "@app-utils/routes";

// import StyledButton from "@app-components/button/StyledButton";

export default function AboutUsPageV2() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="about-us-v2">
      <div className="header-bg">
        <div className="container page-header">
          <div className="heading">{t("page.newAbout.title")}</div>
          <div className="content">{t("page.newAbout.headerContent")}</div>
        </div>
        <div className="sliders-container container">
          <SliderMember />
        </div>
      </div>
      <div className="page-bg">
        <Intros />
        <MidBanner />
        <MemberInfos />
        <div className="footer-banner">
          <div className="container flex flex-col gap-[32px]">
            <div className="title">BE OUR PARTNER</div>
            <div className="btn m-auto">
              <StyledButton
                className="primary mt-button-lg w-[384px]"
                onClick={() => navigate(PATHS.contactsUs)}
              >
                Become a partner
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
