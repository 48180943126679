import classNames from "classnames";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import SliderBlurImage2 from "@app-assets/images/homepage/slider2-blur.png";
import SliderBlurImage1 from "@app-assets/images/homepage/slider-1-blur.png";
import SliderBlurImage3 from "@app-assets/images/homepage/slider-3-blur.png";

const SliderImage2 =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/slider2.png";
const SliderImage1 =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/slider-1.png";
const SliderImage3 =
  "https://manytouches-web-media.s3.ap-southeast-1.amazonaws.com/home/slider-3.png";

export default function SliderImages() {
  const [activeIndex, setActiveIndex] = useState(0);
  const imgs = [
    { image: SliderImage1, placeholder: SliderBlurImage1 },
    { image: SliderImage2, placeholder: SliderBlurImage2 },
    { image: SliderImage3, placeholder: SliderBlurImage3 },
  ];
  return (
    <div className="slider-images w-full container gap-[24px] hidden sm:flex ">
      <div className="flex imgs">
        {imgs.map((itm, index) => (
          <div
            onClick={() => setActiveIndex(index)}
            key={index}
            className={classNames("flex-1 slider", {
              active: activeIndex === index,
              mid:
                (activeIndex < index && index - activeIndex === 1) ||
                (activeIndex > index && activeIndex - index === 1),
            })}
          >
            <div
              className={classNames("bg", { overlay: activeIndex !== index })}
            />
            <LazyLoadImage
              wrapperClassName="cover-center"
              className="secondary"
              width={"100%"}
              height={"100%"}
              placeholderSrc={itm.placeholder}
              src={itm.image}
              alt="The Ring"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
